export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.params.servicePointUri) {
    const { servicePointUri } = to.params;

    const servicePoint = await getServicePointByUriId(servicePointUri as string);

    if (!servicePoint) {
      return navigateTo("/not-found");
    }

    const servicePointByIdState = useState("servicePointByUri", () => servicePoint);
    servicePointByIdState.value = servicePoint;
  }
})
